import { useServiceContainer } from './utils/ServiceContainerSingleton'
import FS from '@wsi/web-core/services/FederationService'

import _AuthotizazionService from '@rca/vue-core/shell/services/AuthorizationService'
import _LogService from '@rca/vue-core/shell/services/LogService'
import ContentService from '@rca/vue-core/shell/services/ContentService'
import _LoginService from '@rca/vue-core/shell/services/LoginService'
import MenuService from '@rca/vue-core/shell/services/MenuService'
import SearchService from '@rca/vue-core/shell/services/SearchService'
import _DebugGuiService from '@rca/vue-core/shell/services/DebugGuiService'
import TrackingService from '@rca/vue-core/shell/services/TrackingService'

const serviceContainer = useServiceContainer()
const LogService = _LogService()

// REVIEW: commentato per test error boundary e/o error handler
// LogService.addWindowListeners()

let cfg = null
try {
  cfg = await (await fetch('/cfg')).json()
  window._ClientConfig = cfg
} catch (error) {
  LogService.error(error)
}

const DebugGuiService = _DebugGuiService({
  cfg,
  serviceContainer
})

const debugConfig = DebugGuiService.getDebugConfig()
if (debugConfig) { cfg = debugConfig }

serviceContainer.setContainerConfig(cfg)

const AuthorizationService = _AuthotizazionService({
  ALLOWED_SCOPES: ['TOOL_BACKOFFICE', 'TOOL_BACKOFFICE_ADMIN']
})
const LoginService = _LoginService()

serviceContainer.registerServices({
  FederationService: new FS(cfg),
  AuthorizationService,
  LogService,
  DebugGuiService,
  ContentService: ContentService(LogService),
  LoginService,
  SearchService: SearchService(),
  MenuService: MenuService({ AuthorizationService, LoginService, cfg }),
  TrackingService: new TrackingService(
    { apiUrl: cfg.API_URL, appName: 'RCA_BOF', ENABLE_TRACKING: cfg.ENABLE_TRACKING },
    {},
    { logger: LogService }
  )
})

import('./bootstrap')
